import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import './fonts/Bitter-Regular.woff';

import useLocalStorage from './hooks/useLocalStorage';
import Timer from './components/Timer';

interface Solution {
  result: string;
  timestamp: string;
  author?: string;
}

const App: React.FC = () => {
  const [author, setAuthor] = useLocalStorage<string>('author', 'anonymous');
  const [solutions, setSolutions] = useLocalStorage<Solution[]>('solutions', []);

  const registerResult = (result: string) => {
    setSolutions([{ author, result, timestamp: new Date().toISOString() }, ...solutions]);
  }

  const removeSolution = (index: number) => {
    setSolutions(solutions.filter((_, i) => i !== index));
  }

  return (
    <>
      <h2>Timer</h2>
      <Timer registerResult={registerResult} />

      <h2>Results</h2>
      <table>
        <tr>
          <th>Author</th>
          <th>Result</th>
          <th>Timestamp</th>
          <th>Actions</th>
        </tr>
        {solutions.map((solution, index) => (
          <tr key={solution.timestamp}>
            <td>{solution.author}</td>
            <td>{solution.result}</td>
            <td>{new Date(solution.timestamp).toLocaleString()}</td>
            <td><button onClick={() => removeSolution(index)}>remove</button></td>
          </tr>
        ))}
      </table>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
