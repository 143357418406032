export default (timeDelta: number): string => {
  let resultTime = '';

  const minute = Math.floor(timeDelta / 60000);
  if (minute < 10) resultTime += '0';
  resultTime += minute + ':';

  let second = Math.floor(timeDelta / 1000);
  if (second > 59) second %= 60;
  if (second < 10) resultTime += '0';
  resultTime += second + ':';

  const mill = Math.floor((timeDelta % 1000) / 10);
  if (mill < 10) resultTime += '0';
  resultTime += mill;

  return resultTime;
};
